/* eslint-disable prettier/prettier */
import React from "react";
import { Table } from "react-bootstrap";
import Loading from "../Loading";
import Styles from "./index.module.css";
import Pagination from "react-bootstrap/Pagination";

type Props = {
    id: string;
    size: string;
    head: JSX.Element | JSX.Element[] | undefined;
    body: JSX.Element[] | undefined;
    isLoading: boolean;
    totalRows: number;
    selectedRowsPerPage?: number;
    page?: number;
    changedCurrentPage?: (newPage: number) => void;
};

const DataTable = (prop: Props) =>
{
    const { id, size, head, body, isLoading, totalRows, selectedRowsPerPage, page, changedCurrentPage } = prop;

    const [currentPage, setCurrentPage] = React.useState(page ?? 1);
    const maxPages = 10;

    React.useEffect(() =>
    {
        if (page)
        {
            setCurrentPage(page);
        }
    }, [page]);

    //Pagination Logic
    let rowsPerPage = 25;
    if (selectedRowsPerPage)
    {
        rowsPerPage = selectedRowsPerPage;
    }

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    let currentRowsBody = body;
    //Si la paginación no se maneja de forma externa al componente via api, la manejará el propio componente
    if (!page && !changedCurrentPage)
    {
        currentRowsBody = !isLoading ? body?.slice(indexOfFirstRow, indexOfLastRow) : [];
        if (currentRowsBody && currentRowsBody?.length === 0 && body && body?.length > 0)
        {
            setCurrentPage(1);
        }
    }

    const requiredPageNumbers = Math.ceil(totalRows / rowsPerPage);
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(requiredPageNumbers); i++)
    {
        pageNumbers.push(i);
    }

    function prevPageOnClickHandler()
    {
        if (currentPage > 1) handlePageChange(currentPage - 1);
    }
    function nextPageOnClickHandler()
    {
        if (currentPage < pageNumbers.length) handlePageChange(currentPage + 1);
    }
    function firstPageOnClickHandler()
    {
        handlePageChange(1);
    }
    function lastPageOnClickHandler()
    {
        handlePageChange(pageNumbers.length);
    }

    const handlePageChange = (newPage: number) =>
    {
        setCurrentPage(newPage);
        if (changedCurrentPage)
        {
            changedCurrentPage(newPage);
        }
    };

    return (
        <>
            {isLoading && <Loading type="component" />}
            {!isLoading && (
                <>
                    <Table responsive id={id} borderless size={size} className={Styles.Table}>
                        <thead className={Styles.Thead}>{head}</thead>
                        <tbody className={Styles.Tbody}>
                            {body?.length === 0 && (
                                <tr key="notFoudKey">
                                    <td className={Styles.NotFoundMsg} colSpan={100}>
                                        <b>No se han encontrado Resultados</b>
                                    </td>
                                </tr>
                            )}
                            {currentRowsBody}
                        </tbody>
                    </Table>
                    <Pagination className={Styles.Pagination} max-size="10">
                        <Pagination.First onClick={firstPageOnClickHandler} key="paginationFirst" />
                        <Pagination.Prev onClick={prevPageOnClickHandler} key="paginationPrev" />
                        {pageNumbers.length > maxPages && currentPage > 1 && <Pagination.Ellipsis />}
                        {pageNumbers.map(number => (
                            <React.Fragment key={number}>
                                {number > currentPage - maxPages / 2 && number < currentPage + maxPages / 2 && (
                                    <Pagination.Item
                                        id={"paginationItemId"}
                                        className={Styles.PaginationItem}
                                        key={number}
                                        active={number === currentPage}
                                        onClick={() =>
                                        {
                                            handlePageChange(number);
                                        }}
                                    >
                                        {number}
                                    </Pagination.Item>
                                )}
                            </React.Fragment>
                        ))}
                        {pageNumbers.length > maxPages && currentPage < maxPages && <Pagination.Ellipsis />}
                        <Pagination.Next onClick={nextPageOnClickHandler} key="paginationNext" />
                        <Pagination.Last onClick={lastPageOnClickHandler} key="paginationLast" />
                    </Pagination>
                </>
            )}
        </>
    );
};

export default DataTable;
