export type CountryPrefix = {
	key: string;
	text: string;
	name: string;
	iso2: string;
	iso3: string;
	phone_code: string;
};

const CountryPrefixes: CountryPrefix[] = [
	{
		key: "Afganistán",
		text: "Afganistán",
		name: "Afganistán",
		iso2: "AF",
		iso3: "AFG",
		phone_code: "+93"
	},
	{
		key: "Albania",
		text: "Albania",
		name: "Albania",
		iso2: "AL",
		iso3: "ALB",
		phone_code: "+355"
	},
	{
		key: "Alemania",
		text: "Alemania",
		name: "Alemania",
		iso2: "DE",
		iso3: "DEU",
		phone_code: "+49"
	},
	{
		key: "Argelia",
		text: "Argelia",
		name: "Argelia",
		iso2: "DZ",
		iso3: "DZA",
		phone_code: "+213"
	},
	{
		key: "Andorra",
		text: "Andorra",
		name: "Andorra",
		iso2: "AD",
		iso3: "Y",
		phone_code: "+376"
	},
	{
		key: "Angola",
		text: "Angola",
		name: "Angola",
		iso2: "AO",
		iso3: "AGO",
		phone_code: "+244"
	},
	{
		key: "Anguila",
		text: "Anguila",
		name: "Anguila",
		iso2: "AI",
		iso3: "AIA",
		phone_code: "+1264"
	},
	{
		key: "Antártida",
		text: "Antártida",
		name: "Antártida",
		iso2: "AQ",
		iso3: "ATA",
		phone_code: "+672"
	},
	{
		key: "Antigua y Barbuda",
		text: "Antigua y Barbuda",
		name: "Antigua y Barbuda",
		iso2: "AG",
		iso3: "ATG",
		phone_code: "+1268"
	},
	{
		key: "Antillas Neerlandesas",
		text: "Antillas Holandesas",
		name: "Antillas Holandesas",
		iso2: "AN",
		iso3: "ANT",
		phone_code: "+599"
	},
	{
		key: "Arabia Saudita",
		text: "Arabia Saudita",
		name: "Arabia Saudita",
		iso2: "SA",
		iso3: "SAU",
		phone_code: "+966"
	},
	{
		key: "Argentina",
		text: "Argentina",
		name: "Argentina",
		iso2: "AR",
		iso3: "ARG",
		phone_code: "+54"
	},
	{
		key: "Armenia",
		text: "Armenia",
		name: "Armenia",
		iso2: "AM",
		iso3: "BRAZO",
		phone_code: "+374"
	},
	{
		key: "Aruba",
		text: "Aruba",
		name: "Aruba",
		iso2: "AW",
		iso3: "ABW",
		phone_code: "+297"
	},
	{
		key: "Australia",
		text: "Australia",
		name: "Australia",
		iso2: "AU",
		iso3: "AUS",
		phone_code: "+61"
	},
	{
		key: "Austria",
		text: "Austria",
		name: "Austria",
		iso2: "AT",
		iso3: "AUT",
		phone_code: "+43"
	},
	{
		key: "Azerbayán",
		text: "Azerbaiyán",
		name: "Azerbaiyán",
		iso2: "AZ",
		iso3: "AZE",
		phone_code: "+994"
	},
	{
		key: "Bélgica",
		text: "Bélgica",
		name: "Bélgica",
		iso2: "SER",
		iso3: "BEL",
		phone_code: "+32"
	},
	{
		key: "Bahamas",
		text: "Bahamas",
		name: "Bahamas",
		iso2: "BS",
		iso3: "BHS",
		phone_code: "+1242"
	},
	{
		key: "Bahréin",
		text: "Bahrein",
		name: "Bahrein",
		iso2: "BH",
		iso3: "BHR",
		phone_code: "+973"
	},
	{
		key: "Bangladesh",
		text: "Bangladesh",
		name: "Bangladesh",
		iso2: "BD",
		iso3: "BGD",
		phone_code: "+880"
	},
	{
		key: "Barbados",
		text: "Barbados",
		name: "Barbados",
		iso2: "BB",
		iso3: "BRB",
		phone_code: "+1246"
	},
	{
		key: "Belice",
		text: "Belice",
		name: "Belice",
		iso2: "BZ",
		iso3: "BLZ",
		phone_code: "+501"
	},
	{
		key: "Benín",
		text: "Benin",
		name: "Benin",
		iso2: "BJ",
		iso3: "BEN",
		phone_code: "+229"
	},
	{
		key: "Bhután",
		text: "Bután",
		name: "Bután",
		iso2: "BT",
		iso3: "BTN",
		phone_code: "+975"
	},
	{
		key: "Bielorrusia",
		text: "Bielorrusia",
		name: "Bielorrusia",
		iso2: "POR",
		iso3: "BLR",
		phone_code: "+375"
	},
	{
		key: "Birmania",
		text: "Myanmar",
		name: "Myanmar",
		iso2: "MM",
		iso3: "MMR",
		phone_code: "+95"
	},
	{
		key: "Bolivia",
		text: "Bolivia",
		name: "Bolivia",
		iso2: "BO",
		iso3: "BOL",
		phone_code: "+591"
	},
	{
		key: "Bosnia y Herzegovina",
		text: "Bosnia y Herzegovina",
		name: "Bosnia y Herzegovina",
		iso2: "BA",
		iso3: "BIH",
		phone_code: "+387"
	},
	{
		key: "Botsuana",
		text: "Botswana",
		name: "Botswana",
		iso2: "BW",
		iso3: "BWA",
		phone_code: "+267"
	},
	{
		key: "Brasil",
		text: "Brasil",
		name: "Brasil",
		iso2: "BR",
		iso3: "SUJETADOR",
		phone_code: "+55"
	},
	{
		key: "Brunei",
		text: "Brunei",
		name: "Brunei",
		iso2: "BN",
		iso3: "BRN",
		phone_code: "+673"
	},
	{
		key: "Bulgaria",
		text: "Bulgaria",
		name: "Bulgaria",
		iso2: "BG",
		iso3: "BGR",
		phone_code: "+359"
	},
	{
		key: "Burkina Faso",
		text: "Burkina Faso",
		name: "Burkina Faso",
		iso2: "BF",
		iso3: "BFA",
		phone_code: "+226"
	},
	{
		key: "Burundi",
		text: "Burundi",
		name: "Burundi",
		iso2: "BI",
		iso3: "BDI",
		phone_code: "+257"
	},
	{
		key: "Cabo Verde",
		text: "Cabo Verde",
		name: "Cabo Verde",
		iso2: "CV",
		iso3: "CPV",
		phone_code: "+238"
	},
	{
		key: "Camboya",
		text: "Camboya",
		name: "Camboya",
		iso2: "KH",
		iso3: "KHM",
		phone_code: "+855"
	},
	{
		key: "Camerún",
		text: "Camerún",
		name: "Camerún",
		iso2: "CM",
		iso3: "CMR",
		phone_code: "+237"
	},
	{
		key: "Canadá",
		text: "Canadá",
		name: "Canadá",
		iso2: "CA",
		iso3: "PUEDE",
		phone_code: "+1"
	},
	{
		key: "Chad",
		text: "Chad",
		name: "Chad",
		iso2: "TD",
		iso3: "TCD",
		phone_code: "+235"
	},
	{
		key: "Chile",
		text: "Chile",
		name: "Chile",
		iso2: "CL",
		iso3: "CHL",
		phone_code: "+56"
	},
	{
		key: "China",
		text: "China",
		name: "China",
		iso2: "CN",
		iso3: "CHN",
		phone_code: "+86"
	},
	{
		key: "Chipre",
		text: "Chipre",
		name: "Chipre",
		iso2: "CY",
		iso3: "CYP",
		phone_code: "+357"
	},
	{
		key: "Ciudad del Vaticano",
		text: "Estado de la Ciudad del Vaticano",
		name: "Ciudad del Vaticano",
		iso2: "VA",
		iso3: "IVA",
		phone_code: "+39"
	},
	{
		key: "Colombia",
		text: "Colombia",
		name: "Colombia",
		iso2: "CO",
		iso3: "COL",
		phone_code: "+57"
	},
	{
		key: "Comoras",
		text: "Comoras",
		name: "Comoras",
		iso2: "KM",
		iso3: "COM",
		phone_code: "+269"
	},
	{
		key: "Congo",
		text: "Congo",
		name: "Congo",
		iso2: "CG",
		iso3: "COG",
		phone_code: "+242"
	},
	{
		key: "Corea del Norte",
		text: "Corea del Norte",
		name: "Corea del Norte",
		iso2: "KP",
		iso3: "PRK",
		phone_code: "+850"
	},
	{
		key: "Corea del Sur",
		text: "Corea del Sur",
		name: "Corea del Sur",
		iso2: "KR",
		iso3: "KOR",
		phone_code: "+82"
	},
	{
		key: "Costa de Marfil",
		text: "Costa de Marfil",
		name: "Costa de Marfil",
		iso2: "CI",
		iso3: "CIV",
		phone_code: "+225"
	},
	{
		key: "Costa Rica",
		text: "Costa Rica",
		name: "Costa Rica",
		iso2: "CR",
		iso3: "CRI",
		phone_code: "+506"
	},
	{
		key: "Croacia",
		text: "Croacia",
		name: "Croacia",
		iso2: "HR",
		iso3: "VFC",
		phone_code: "+385"
	},
	{
		key: "Cuba",
		text: "Cuba",
		name: "Cuba",
		iso2: "CU",
		iso3: "CUB",
		phone_code: "+53"
	},
	{
		key: "Dinamarca",
		text: "Dinamarca",
		name: "Dinamarca",
		iso2: "DK",
		iso3: "DNK",
		phone_code: "+45"
	},
	{
		key: "Dominica",
		text: "Dominica",
		name: "Dominique",
		iso2: "DM",
		iso3: "DMA",
		phone_code: "+1767"
	},
	{
		key: "Ecuador",
		text: "Ecuador",
		name: "Ecuador",
		iso2: "EC",
		iso3: "ECU",
		phone_code: "+593"
	},
	{
		key: "Egipto",
		text: "Egipto",
		name: "Egipto",
		iso2: "EG",
		iso3: "EGY",
		phone_code: "+20"
	},
	{
		key: "El Salvador",
		text: "El Salvador",
		name: "El Salvador",
		iso2: "SV",
		iso3: "SLV",
		phone_code: "+503"
	},
	{
		key: "Emiratos Árabes Unidos",
		text: "Emiratos Árabes Unidos",
		name: "Emiratos Árabes Unidos",
		iso2: "AE",
		iso3: "SON",
		phone_code: "+971"
	},
	{
		key: "Eritrea",
		text: "Eritrea",
		name: "Eritrea",
		iso2: "ER",
		iso3: "ERI",
		phone_code: "+291"
	},
	{
		key: "Eslovaquia",
		text: "Eslovaquia",
		name: "Eslovaquia",
		iso2: "SK",
		iso3: "SVK",
		phone_code: "+421"
	},
	{
		key: "Eslovenia",
		text: "Eslovenia",
		name: "Eslovenia",
		iso2: "SI",
		iso3: "SVN",
		phone_code: "+386"
	},
	{
		key: "España",
		text: "España",
		name: "España",
		iso2: "ES",
		iso3: "ESP",
		phone_code: "+34"
	},
	{
		key: "Estados Unidos de América",
		text: "Estados Unidos de América",
		name: "Estados Unidos de América",
		iso2: "EE. UU.",
		iso3: "EE. UU.",
		phone_code: "+1"
	},
	{
		key: "Estonia",
		text: "Estonia",
		name: "Estonia",
		iso2: "EE",
		iso3: "EST",
		phone_code: "+372"
	},
	{
		key: "Etiopía",
		text: "Etiopía",
		name: "Etiopía",
		iso2: "Y",
		iso3: "ETH",
		phone_code: "+251"
	},
	{
		key: "Filipinas",
		text: "Filipinas",
		name: "Filipinas",
		iso2: "PH",
		iso3: "PHL",
		phone_code: "+63"
	},
	{
		key: "Finlandia",
		text: "Finlandia",
		name: "Finlandia",
		iso2: "FI",
		iso3: "FIN",
		phone_code: "+358"
	},
	{
		key: "Fiyi",
		text: "Fiji",
		name: "Fiji",
		iso2: "FJ",
		iso3: "FJI",
		phone_code: "+679"
	},
	{
		key: "Francia",
		text: "Francia",
		name: "Francia",
		iso2: "FR",
		iso3: "FRA",
		phone_code: "+33"
	},
	{
		key: "Gabón",
		text: "Gabón",
		name: "Gabón",
		iso2: "GA",
		iso3: "GAB",
		phone_code: "+241"
	},
	{
		key: "Gambia",
		text: "Gambia",
		name: "Gambia",
		iso2: "GM",
		iso3: "GMB",
		phone_code: "+220"
	},
	{
		key: "Georgia",
		text: "Georgia",
		name: "Georgia",
		iso2: "GE",
		iso3: "GEO",
		phone_code: "+995"
	},
	{
		key: "Ghana",
		text: "Ghana",
		name: "Ghana",
		iso2: "GH",
		iso3: "GHA",
		phone_code: "+233"
	},
	{
		key: "Gibraltar",
		text: "Gibraltar",
		name: "Gibraltar",
		iso2: "GI",
		iso3: "GIB",
		phone_code: "+350"
	},
	{
		key: "Granada",
		text: "Granada",
		name: "Granada",
		iso2: "GD",
		iso3: "GRD",
		phone_code: "+1473"
	},
	{
		key: "Grecia",
		text: "Grecia",
		name: "Grecia",
		iso2: "GR",
		iso3: "GRC",
		phone_code: "+30"
	},
	{
		key: "Groenlandia",
		text: "Groenlandia",
		name: "Groenlandia",
		iso2: "GL",
		iso3: "GRL",
		phone_code: "+299"
	},
	{
		key: "Guadalupe",
		text: "Guadalupe",
		name: "Guadalupe",
		iso2: "GP",
		iso3: "GLP",
		phone_code: ""
	},
	{
		key: "Guam",
		text: "Guam",
		name: "Guam",
		iso2: "GU",
		iso3: "GOMA",
		phone_code: "+1671"
	},
	{
		key: "Guatemala",
		text: "Guatemala",
		name: "Guatemala",
		iso2: "GT",
		iso3: "GTM",
		phone_code: "+502"
	},
	{
		key: "Guayana Francesa",
		text: "Guayana Francesa",
		name: "Guayana Francesa",
		iso2: "GF",
		iso3: "GUF",
		phone_code: ""
	},
	{
		key: "Guernsey",
		text: "Guernsey",
		name: "Guernsey",
		iso2: "GG",
		iso3: "GGY",
		phone_code: ""
	},
	{
		key: "Guinea",
		text: "Guinea",
		name: "Guinea",
		iso2: "GN",
		iso3: "GIN",
		phone_code: "+224"
	},
	{
		key: "Guinea Ecuatorial",
		text: "Guinea Ecuatorial",
		name: "Guinea Ecuatorial",
		iso2: "GQ",
		iso3: "GNQ",
		phone_code: "+240"
	},
	{
		key: "Guinea-Bissau",
		text: "Guinea-Bissau",
		name: "Guinea-Bissau",
		iso2: "GW",
		iso3: "GNB",
		phone_code: "+245"
	},
	{
		key: "Guyana",
		text: "Guyana",
		name: "Guyana",
		iso2: "GY",
		iso3: "CHICO",
		phone_code: "+592"
	},
	{
		key: "Haití",
		text: "Haití",
		name: "Haití",
		iso2: "HT",
		iso3: "HTI",
		phone_code: "+509"
	},
	{
		key: "Honduras",
		text: "Honduras",
		name: "Honduras",
		iso2: "HN",
		iso3: "HND",
		phone_code: "+504"
	},
	{
		key: "Hong kong",
		text: "Hong Kong",
		name: "Hong Kong",
		iso2: "HK",
		iso3: "HKG",
		phone_code: "+852"
	},
	{
		key: "Hungría",
		text: "Hungría",
		name: "Hungría",
		iso2: "HU",
		iso3: "HUN",
		phone_code: "+36"
	},
	{
		key: "India",
		text: "India",
		name: "India",
		iso2: "IN",
		iso3: "IND",
		phone_code: "+91"
	},
	{
		key: "Indonesia",
		text: "Indonesia",
		name: "Indonesia",
		iso2: "ID",
		iso3: "IDN",
		phone_code: "+62"
	},
	{
		key: "Irán",
		text: "Irán",
		name: "Irán",
		iso2: "IR",
		iso3: "IRN",
		phone_code: "+98"
	},
	{
		key: "Irak",
		text: "Irak",
		name: "Irak",
		iso2: "IQ",
		iso3: "IRQ",
		phone_code: "+964"
	},
	{
		key: "Irlanda",
		text: "Irlanda",
		name: "Irlanda",
		iso2: "IE",
		iso3: "IRL",
		phone_code: "+353"
	},
	{
		key: "Isla Bouvet",
		text: "Isla Bouvet",
		name: "Isla Bouvet",
		iso2: "BV",
		iso3: "BVT",
		phone_code: ""
	},
	{
		key: "Isla de Man",
		text: "Isla de Man",
		name: "Isla de Man",
		iso2: "MI",
		iso3: "IMN",
		phone_code: "+44"
	},
	{
		key: "Isla de Navidad",
		text: "Isla de Navidad",
		name: "Isla de Navidad",
		iso2: "CX",
		iso3: "CXR",
		phone_code: "+61"
	},
	{
		key: "Isla Norfolk",
		text: "Isla Norfolk",
		name: "Isla Norfolk",
		iso2: "NF",
		iso3: "NFK",
		phone_code: ""
	},
	{
		key: "Islandia",
		text: "Islandia",
		name: "Islandia",
		iso2: "ES",
		iso3: "ISL",
		phone_code: "+354"
	},
	{
		key: "Islas Bermudas",
		text: "Islas Bermudas",
		name: "Bermudas",
		iso2: "BM",
		iso3: "UMB",
		phone_code: "+1441"
	},
	{
		key: "Islas Caimán",
		text: "Islas Caimán",
		name: "Islas Caimán",
		iso2: "KY",
		iso3: "CYM",
		phone_code: "+1345"
	},
	{
		key: "Islas Cocos (Keeling)",
		text: "Islas Cocos (Keeling)",
		name: "Cocos (Keeling",
		iso2: "CC",
		iso3: "CCK",
		phone_code: "+61"
	},
	{
		key: "Islas Cook",
		text: "Islas Cook",
		name: "Islas Cook",
		iso2: "CK",
		iso3: "COK",
		phone_code: "+682"
	},
	{
		key: "Islas de Åland",
		text: "Islas Åland",
		name: "Islas Åland",
		iso2: "AX",
		iso3: "ALA",
		phone_code: ""
	},
	{
		key: "Islas Feroe",
		text: "Islas Feroe",
		name: "Islas Faros",
		iso2: "FO",
		iso3: "FRO",
		phone_code: "+298"
	},
	{
		key: "Islas Georgias del Sur y Sandwich del Sur",
		text: "Islas Georgias del Sur y Sandwich del Sur",
		name: "Islas Georgias del Sur y Sandwich del Sur",
		iso2: "GS",
		iso3: "SGS",
		phone_code: ""
	},
	{
		key: "Islas Heard y McDonald",
		text: "Islas Heard y McDonald",
		name: "Islas Heard y McDonald",
		iso2: "HM",
		iso3: "HMD",
		phone_code: ""
	},
	{
		key: "Islas Maldivas",
		text: "Maldivas",
		name: "Maldivas",
		iso2: "MV",
		iso3: "MDV",
		phone_code: "+960"
	},
	{
		key: "Islas Malvinas",
		text: "Islas Malvinas (Falkland)",
		name: "Islas Malvinas",
		iso2: "FK",
		iso3: "FLK",
		phone_code: "+500"
	},
	{
		key: "Islas Marianas del Norte",
		text: "Islas Marianas del Norte",
		name: "Islas Marianas del Norte",
		iso2: "MP",
		iso3: "MNP",
		phone_code: "+1670"
	},
	{
		key: "Islas Marshall",
		text: "Islas Marshall",
		name: "Islas Marshall",
		iso2: "MH",
		iso3: "MHL",
		phone_code: "+692"
	},
	{
		key: "Islas Pitcairn",
		text: "Islas Pitcairn",
		name: "Islas Pitcairn",
		iso2: "PN",
		iso3: "PCN",
		phone_code: "+870"
	},
	{
		key: "Islas Salomón",
		text: "Islas Salomón",
		name: "Islas Salomón",
		iso2: "SB",
		iso3: "SLB",
		phone_code: "+677"
	},
	{
		key: "Islas Turcas y Caicos",
		text: "Islas Turcas y Caicos",
		name: "Islas Turcas y Caicos",
		iso2: "TC",
		iso3: "TCA",
		phone_code: "+1649"
	},
	{
		key: "Islas Ultramarinas Menores de Estados Unidos",
		text: "Islas menores alejadas de los Estados Unidos",
		name: "Islas menores alejadas de los Estados Unidos",
		iso2: "UM",
		iso3: "UMI",
		phone_code: ""
	},
	{
		key: "Islas Vírgenes Británicas",
		text: "Islas Vírgenes",
		name: "Islas Vírgenes",
		iso2: "VG",
		iso3: "VG",
		phone_code: "+1284"
	},
	{
		key: "Islas Vírgenes de los Estados Unidos",
		text: "Islas Vírgenes de los Estados Unidos",
		name: "Islas Vírgenes de EE. UU.",
		iso2: "VI",
		iso3: "VIR",
		phone_code: "+1340"
	},
	{
		key: "Israel",
		text: "Israel",
		name: "Israel",
		iso2: "IL",
		iso3: "ISR",
		phone_code: "+972"
	},
	{
		key: "Italia",
		text: "Italia",
		name: "Italia",
		iso2: "IT",
		iso3: "ITA",
		phone_code: "+39"
	},
	{
		key: "Jamaica",
		text: "Jamaica",
		name: "Jamaica",
		iso2: "JM",
		iso3: "ATASCO",
		phone_code: "+1876"
	},
	{
		key: "Japón",
		text: "Japón",
		name: "Japón",
		iso2: "JP",
		iso3: "JPN",
		phone_code: "+81"
	},
	{
		key: "Jersey",
		text: "Jersey",
		name: "Jersey",
		iso2: "Yo",
		iso3: "JEY",
		phone_code: ""
	},
	{
		key: "Jordania",
		text: "Jordania",
		name: "Jordania",
		iso2: "JO",
		iso3: "JOR",
		phone_code: "+962"
	},
	{
		key: "Kazajistán",
		text: "Kazajstán",
		name: "Kazajstán",
		iso2: "KZ",
		iso3: "KAZ",
		phone_code: "+7"
	},
	{
		key: "Kenia",
		text: "Kenia",
		name: "Kenia",
		iso2: "KE",
		iso3: "KEN",
		phone_code: "+254"
	},
	{
		key: "Kirgizstán",
		text: "Kirguistán",
		name: "Kirguistán",
		iso2: "KG",
		iso3: "KGZ",
		phone_code: "+996"
	},
	{
		key: "Kiribati",
		text: "Kiribati",
		name: "Kiribati",
		iso2: "KI",
		iso3: "KIR",
		phone_code: "+686"
	},
	{
		key: "Kuwait",
		text: "Kuwait",
		name: "Kuwait",
		iso2: "KW",
		iso3: "KWT",
		phone_code: "+965"
	},
	{
		key: "Líbano",
		text: "Líbano",
		name: "Líbano",
		iso2: "LB",
		iso3: "LBN",
		phone_code: "+961"
	},
	{
		key: "Laos",
		text: "Laos",
		name: "Laos",
		iso2: "LA",
		iso3: "LAO",
		phone_code: "+856"
	},
	{
		key: "Lesoto",
		text: "Lesotho",
		name: "Lesoto",
		iso2: "LS",
		iso3: "LSO",
		phone_code: "+266"
	},
	{
		key: "Letonia",
		text: "Letonia",
		name: "Letonia",
		iso2: "LV",
		iso3: "LVA",
		phone_code: "+371"
	},
	{
		key: "Liberia",
		text: "Liberia",
		name: "Liberia",
		iso2: "LR",
		iso3: "LBR",
		phone_code: "+231"
	},
	{
		key: "Libia",
		text: "Libia",
		name: "Libia",
		iso2: "LY",
		iso3: "LBY",
		phone_code: "+218"
	},
	{
		key: "Liechtenstein",
		text: "Liechtenstein",
		name: "Liechtenstein",
		iso2: "LI",
		iso3: "MENTIRA",
		phone_code: "+423"
	},
	{
		key: "Lituania",
		text: "Lituania",
		name: "Lituania",
		iso2: "LT",
		iso3: "LTU",
		phone_code: "+370"
	},
	{
		key: "Luxemburgo",
		text: "Luxemburgo",
		name: "Luxemburgo",
		iso2: "LU",
		iso3: "LUX",
		phone_code: "+352"
	},
	{
		key: "México",
		text: "México",
		name: "México",
		iso2: "MX",
		iso3: "MEX",
		phone_code: "+52"
	},
	{
		key: "Mónaco",
		text: "Mónaco",
		name: "Mónaco",
		iso2: "MC",
		iso3: "MCO",
		phone_code: "+377"
	},
	{
		key: "Macao",
		text: "Macao",
		name: "Macao",
		iso2: "MO",
		iso3: "MAC",
		phone_code: "+853"
	},
	{
		key: "Macedonia",
		text: "Macedonia",
		name: "Macedonia",
		iso2: "MK",
		iso3: "MKD",
		phone_code: "+389"
	},
	{
		key: "Madagascar",
		text: "Madagascar",
		name: "Madagascar",
		iso2: "MG",
		iso3: "ODM",
		phone_code: "+261"
	},
	{
		key: "Malasia",
		text: "Malasia",
		name: "Malasia",
		iso2: "MI",
		iso3: "MIS",
		phone_code: "+60"
	},
	{
		key: "Malawi",
		text: "Malawi",
		name: "Malawi",
		iso2: "MW",
		iso3: "MWI",
		phone_code: "+265"
	},
	{
		key: "Mali",
		text: "Mali",
		name: "Mali",
		iso2: "ML",
		iso3: "MLI",
		phone_code: "+223"
	},
	{
		key: "Malta",
		text: "Malta",
		name: "Malta",
		iso2: "MT",
		iso3: "MLT",
		phone_code: "+356"
	},
	{
		key: "Marruecos",
		text: "Marruecos",
		name: "Marruecos",
		iso2: "MA",
		iso3: "MAR",
		phone_code: "+212"
	},
	{
		key: "Martinica",
		text: "Martinica",
		name: "Martinica",
		iso2: "MQ",
		iso3: "MTQ",
		phone_code: ""
	},
	{
		key: "Mauricio",
		text: "Mauricio",
		name: "Islas Mauricio",
		iso2: "MU",
		iso3: "MUS",
		phone_code: "+230"
	},
	{
		key: "Mauritania",
		text: "Mauritania",
		name: "Mauritania",
		iso2: "MR",
		iso3: "MRT",
		phone_code: "+222"
	},
	{
		key: "Mayotte",
		text: "Mayotte",
		name: "Mayotte",
		iso2: "YT",
		iso3: "MYT",
		phone_code: "+262"
	},
	{
		key: "Micronesia",
		text: "Estados Federados de",
		name: "Federados Estados de",
		iso2: "FM",
		iso3: "FSM",
		phone_code: "+691"
	},
	{
		key: "Moldavia",
		text: "Moldavia",
		name: "Moldavia",
		iso2: "MD",
		iso3: "MDA",
		phone_code: "+373"
	},
	{
		key: "Mongolia",
		text: "Mongolia",
		name: "Mongolia",
		iso2: "MN",
		iso3: "MNG",
		phone_code: "+976"
	},
	{
		key: "Montenegro",
		text: "Montenegro",
		name: "Montenegro",
		iso2: "YO",
		iso3: "MNE",
		phone_code: "+382"
	},
	{
		key: "Montserrat",
		text: "Montserrat",
		name: "Montserrat",
		iso2: "MS",
		iso3: "MSR",
		phone_code: "+1664"
	},
	{
		key: "Mozambique",
		text: "Mozambique",
		name: "Mozambique",
		iso2: "MZ",
		iso3: "MOZ",
		phone_code: "+258"
	},
	{
		key: "Namibia",
		text: "Namibia",
		name: "Namibia",
		iso2: "NA",
		iso3: "NAM",
		phone_code: "+264"
	},
	{
		key: "Nauru",
		text: "Nauru",
		name: "Nauru",
		iso2: "NR",
		iso3: "NRU",
		phone_code: "+674"
	},
	{
		key: "Nepal",
		text: "Nepal",
		name: "Nepal",
		iso2: "NP",
		iso3: "NPL",
		phone_code: "+977"
	},
	{
		key: "Nicaragua",
		text: "Nicaragua",
		name: "Nicaragua",
		iso2: "NI",
		iso3: "NIC",
		phone_code: "+505"
	},
	{
		key: "Níger",
		text: "Níger",
		name: "Níger",
		iso2: "NE",
		iso3: "NER",
		phone_code: "+227"
	},
	{
		key: "Nigeria",
		text: "Nigeria",
		name: "Nigeria",
		iso2: "NG",
		iso3: "NGA",
		phone_code: "+234"
	},
	{
		key: "Niue",
		text: "Niue",
		name: "Niou",
		iso2: "NU",
		iso3: "NIU",
		phone_code: "+683"
	},
	{
		key: "Noruega",
		text: "Noruega",
		name: "Noruega",
		iso2: "NO",
		iso3: "NOR",
		phone_code: "+47"
	},
	{
		key: "Nueva Caledonia",
		text: "Nueva Caledonia",
		name: "Nueva Caledonia",
		iso2: "NC",
		iso3: "NCL",
		phone_code: "+687"
	},
	{
		key: "Nueva Zelanda",
		text: "Nueva Zelanda",
		name: "Nueva Zelanda",
		iso2: "NZ",
		iso3: "NZL",
		phone_code: "+64"
	},
	{
		key: "Omán",
		text: "Omán",
		name: "Omán",
		iso2: "OM",
		iso3: "OMN",
		phone_code: "+968"
	},
	{
		key: "Países Bajos",
		text: "Países Bajos",
		name: "Países Bajos",
		iso2: "NL",
		iso3: "NLD",
		phone_code: "+31"
	},
	{
		key: "Pakistán",
		text: "Pakistán",
		name: "Pakistán",
		iso2: "PK",
		iso3: "PAK",
		phone_code: "+92"
	},
	{
		key: "Palau",
		text: "Palau",
		name: "Palau",
		iso2: "PW",
		iso3: "PLW",
		phone_code: "+680"
	},
	{
		key: "Palestina",
		text: "Palestina",
		name: "Palestina",
		iso2: "PS",
		iso3: "PSE",
		phone_code: ""
	},
	{
		key: "Panamá",
		text: "Panamá",
		name: "Panamá",
		iso2: "PA",
		iso3: "PAN",
		phone_code: "+507"
	},
	{
		key: "Papúa Nueva Guinea",
		text: "Papua Nueva Guinea",
		name: "Papua Nueva Guinea",
		iso2: "PG",
		iso3: "PNG",
		phone_code: "+675"
	},
	{
		key: "Paraguay",
		text: "Paraguay",
		name: "Paraguay",
		iso2: "PY",
		iso3: "PRY",
		phone_code: "+595"
	},
	{
		key: "Perú",
		text: "Perú",
		name: "Perú",
		iso2: "PE",
		iso3: "PER",
		phone_code: "+51"
	},
	{
		key: "Polinesia Francesa",
		text: "Polinesia Francesa",
		name: "Polinesia francesa",
		iso2: "PF",
		iso3: "PYF",
		phone_code: "+689"
	},
	{
		key: "Polonia",
		text: "Polonia",
		name: "Polonia",
		iso2: "PL",
		iso3: "POL",
		phone_code: "+48"
	},
	{
		key: "Portugal",
		text: "Portugal",
		name: "Portugal",
		iso2: "PT",
		iso3: "PRT",
		phone_code: "+351"
	},
	{
		key: "Puerto Rico",
		text: "Puerto Rico",
		name: "Puerto Rico",
		iso2: "PR",
		iso3: "PRI",
		phone_code: "+1"
	},
	{
		key: "Qatar",
		text: "Qatar",
		name: "Qatar",
		iso2: "Control de calidad",
		iso3: "QAT",
		phone_code: "+974"
	},
	{
		key: "Reino Unido",
		text: "Reino Unido",
		name: "Reino Unido",
		iso2: "GB",
		iso3: "GBR",
		phone_code: "+44"
	},
	{
		key: "República Centroafricana",
		text: "República Centroafricana",
		name: "República Centroafricana",
		iso2: "CF",
		iso3: "CAF",
		phone_code: "+236"
	},
	{
		key: "República Checa",
		text: "República Checa",
		name: "República Checa",
		iso2: "CZ",
		iso3: "CZE",
		phone_code: "+420"
	},
	{
		key: "República Dominicana",
		text: "República Dominicana",
		name: "República Dominicana",
		iso2: "HACER",
		iso3: "DOM",
		phone_code: "+1 809"
	},
	{
		key: "Reunión",
		text: "Reunión",
		name: "Reunión",
		iso2: "RE",
		iso3: "REU",
		phone_code: ""
	},
	{
		key: "Ruanda",
		text: "Ruanda",
		name: "Ruanda",
		iso2: "RW",
		iso3: "RWA",
		phone_code: "+250"
	},
	{
		key: "Rumanía",
		text: "Rumania",
		name: "Rumania",
		iso2: "RO",
		iso3: "ROU",
		phone_code: "+40"
	},
	{
		key: "Rusia",
		text: "Rusia",
		name: "Rusia",
		iso2: "RU",
		iso3: "RUS",
		phone_code: "+7"
	},
	{
		key: "Sáhara Occidental",
		text: "Sáhara Occidental",
		name: "Sáhara Occidental",
		iso2: "EH",
		iso3: "ESH",
		phone_code: ""
	},
	{
		key: "Samoa",
		text: "Samoa",
		name: "Samoa",
		iso2: "WS",
		iso3: "WSM",
		phone_code: "+685"
	},
	{
		key: "Samoa Americana",
		text: "Samoa Americana",
		name: "Samoa Americana",
		iso2: "AS",
		iso3: "ASM",
		phone_code: "+1 684"
	},
	{
		key: "San Bartolomé",
		text: "San Bartolomé",
		name: "Saint-Barthélemy",
		iso2: "BL",
		iso3: "BLM",
		phone_code: "+590"
	},
	{
		key: "San Cristóbal y Nieves",
		text: "Saint Kitts y Nevis",
		name: "Saint Kitts y Nevis",
		iso2: "KN",
		iso3: "KNA",
		phone_code: "+1 869"
	},
	{
		key: "San Marino",
		text: "San Marino",
		name: "San Marino",
		iso2: "SM",
		iso3: "SMR",
		phone_code: "+378"
	},
	{
		key: "San Martín (Francia)",
		text: "San Martín (parte francesa)",
		name: "Saint-Martin (parte francesa)",
		iso2: "MF",
		iso3: "MAF",
		phone_code: "+1599"
	},
	{
		key: "San Pedro y Miquelón",
		text: "San Pedro y Miquelón",
		name: "Saint-Pierre-et-Miquelon",
		iso2: "PM",
		iso3: "SPM",
		phone_code: "+508"
	},
	{
		key: "San Vicente y las Granadinas",
		text: "San Vicente y las Granadinas",
		name: "San Vicente y las Granadinas",
		iso2: "VC",
		iso3: "VCT",
		phone_code: "+1784"
	},
	{
		key: "Santa Elena",
		text: "Ascensión y Tristán de Acuña",
		name: "Ascensión y Tristan de Acuña",
		iso2: "SH",
		iso3: "SHN",
		phone_code: "+290"
	},
	{
		key: "Santa Lucía",
		text: "Santa Lucía",
		name: "Santa Lucía",
		iso2: "LC",
		iso3: "LCA",
		phone_code: "+1758"
	},
	{
		key: "Santo Tomé y Príncipe",
		text: "Santo Tomé y Príncipe",
		name: "Santo Tomé y Príncipe",
		iso2: "ST",
		iso3: "STP",
		phone_code: "+239"
	},
	{
		key: "Senegal",
		text: "Senegal",
		name: "Senegal",
		iso2: "SN",
		iso3: "SEN",
		phone_code: "+221"
	},
	{
		key: "Serbia",
		text: "Serbia",
		name: "Serbia",
		iso2: "RS",
		iso3: "SRB",
		phone_code: "+381"
	},
	{
		key: "Seychelles",
		text: "Seychelles",
		name: "Seychelles",
		iso2: "SC",
		iso3: "SYC",
		phone_code: "+248"
	},
	{
		key: "Sierra Leona",
		text: "Sierra Leona",
		name: "Sierra Leona",
		iso2: "SL",
		iso3: "SLE",
		phone_code: "+232"
	},
	{
		key: "Singapur",
		text: "Singapur",
		name: "Singapur",
		iso2: "SG",
		iso3: "SGP",
		phone_code: "+65"
	},
	{
		key: "Siria",
		text: "Siria",
		name: "Siria",
		iso2: "SY",
		iso3: "SYR",
		phone_code: "+963"
	},
	{
		key: "Somalia",
		text: "Somalia",
		name: "Somalia",
		iso2: "ASÍ",
		iso3: "SOM",
		phone_code: "+252"
	},
	{
		key: "Sri Lanka",
		text: "Sri Lanka",
		name: "Sri Lanka",
		iso2: "LK",
		iso3: "LKA",
		phone_code: "+94"
	},
	{
		key: "Sudáfrica",
		text: "Sudáfrica",
		name: "Sudáfrica",
		iso2: "ZA",
		iso3: "ZAF",
		phone_code: "+27"
	},
	{
		key: "Sudán",
		text: "Sudán",
		name: "Sudán",
		iso2: "SD",
		iso3: "SDN",
		phone_code: "+249"
	},
	{
		key: "Suecia",
		text: "Suecia",
		name: "Suecia",
		iso2: "SE",
		iso3: "SWE",
		phone_code: "+46"
	},
	{
		key: "Suiza",
		text: "Suiza",
		name: "Suiza",
		iso2: "CH",
		iso3: "CHE",
		phone_code: "+41"
	},
	{
		key: "Surinám",
		text: "Suritext",
		name: "Surinam",
		iso2: "SR",
		iso3: "ACTIVADO",
		phone_code: "+597"
	},
	{
		key: "Svalbard y Jan Mayen",
		text: "Svalbard y Jan Mayen",
		name: "Svalbard y Jan Mayen",
		iso2: "SJ",
		iso3: "SJM",
		phone_code: ""
	},
	{
		key: "Swazilandia",
		text: "Swazilandia",
		name: "Swazilandia",
		iso2: "SZ",
		iso3: "SWZ",
		phone_code: "+268"
	},
	{
		key: "Tadjikistán",
		text: "Tayikistán",
		name: "Tayikistán",
		iso2: "TJ",
		iso3: "TJK",
		phone_code: "+992"
	},
	{
		key: "Tailandia",
		text: "Tailandia",
		name: "Tailandia",
		iso2: "TH",
		iso3: "THA",
		phone_code: "+66"
	},
	{
		key: "Taiwán",
		text: "Taiwán",
		name: "Taiwán",
		iso2: "TW",
		iso3: "TWN",
		phone_code: "+886"
	},
	{
		key: "Tanzania",
		text: "Tanzania",
		name: "Tanzania",
		iso2: "TZ",
		iso3: "TZA",
		phone_code: "+255"
	},
	{
		key: "Territorio Británico del Océano Índico",
		text: "Territorio Británico del Océano Índico",
		name: "Territorio Británico del Océano Índico",
		iso2: "IO",
		iso3: "IOT",
		phone_code: ""
	},
	{
		key: "Territorios Australes y Antárticas Franceses",
		text: "Territorios Australes Franceses",
		name: "Territorios Australes Franceses",
		iso2: "TF",
		iso3: "ATF",
		phone_code: ""
	},
	{
		key: "Timor Oriental",
		text: "Timor Oriental",
		name: "Timor Oriental",
		iso2: "TL",
		iso3: "TLS",
		phone_code: "+670"
	},
	{
		key: "Togo",
		text: "Togo",
		name: "Togo",
		iso2: "TG",
		iso3: "TGO",
		phone_code: "+228"
	},
	{
		key: "Tokelau",
		text: "Tokelau",
		name: "Tokelau",
		iso2: "TK",
		iso3: "TKL",
		phone_code: "+690"
	},
	{
		key: "Tonga",
		text: "Tonga",
		name: "Tonga",
		iso2: "A",
		iso3: "TON",
		phone_code: "+676"
	},
	{
		key: "Trinidad y Tobago",
		text: "Trinidad y Tobago",
		name: "Trinidad y Tobago",
		iso2: "TT",
		iso3: "TTO",
		phone_code: "+1 868"
	},
	{
		key: "Tunez",
		text: "Túnez",
		name: "Túnez",
		iso2: "TN",
		iso3: "TUN",
		phone_code: "+216"
	},
	{
		key: "Turkmenistán",
		text: "Turkmenistán",
		name: "Turkmenistán",
		iso2: "TM",
		iso3: "TKM",
		phone_code: "+993"
	},
	{
		key: "Turquía",
		text: "Turquía",
		name: "Turquía",
		iso2: "TR",
		iso3: "TUR",
		phone_code: "+90"
	},
	{
		key: "Tuvalu",
		text: "Tuvalu",
		name: "Tuvalu",
		iso2: "TV",
		iso3: "TUV",
		phone_code: "+688"
	},
	{
		key: "Ucrania",
		text: "Ucrania",
		name: "Ucrania",
		iso2: "UA",
		iso3: "UKR",
		phone_code: "+380"
	},
	{
		key: "Uganda",
		text: "Uganda",
		name: "Uganda",
		iso2: "UG",
		iso3: "UGA",
		phone_code: "+256"
	},
	{
		key: "Uruguay",
		text: "Uruguay",
		name: "Uruguay",
		iso2: "UY",
		iso3: "URY",
		phone_code: "+598"
	},
	{
		key: "Uzbekistán",
		text: "Uzbekistán",
		name: "Uzbekistán",
		iso2: "UZ",
		iso3: "UZB",
		phone_code: "+998"
	},
	{
		key: "Vanuatu",
		text: "Vanuatu",
		name: "Vanuatu",
		iso2: "VU",
		iso3: "VUT",
		phone_code: "+678"
	},
	{
		key: "Venezuela",
		text: "Venezuela",
		name: "Venezuela",
		iso2: "VE",
		iso3: "VEN",
		phone_code: "+58"
	},
	{
		key: "Vietnam",
		text: "Vietnam",
		name: "Vietnam",
		iso2: "VN",
		iso3: "VNM",
		phone_code: "+84"
	},
	{
		key: "Wallis y Futuna",
		text: "Wallis y Futuna",
		name: "Wallis y Futuna",
		iso2: "WF",
		iso3: "WLF",
		phone_code: "+681"
	},
	{
		key: "Yemen",
		text: "Yemen",
		name: "Yemen",
		iso2: "SÍ",
		iso3: "YEM",
		phone_code: "+967"
	},
	{
		key: "Yibuti",
		text: "Djibouti",
		name: "Djibouti",
		iso2: "DJ",
		iso3: "DJI",
		phone_code: "+253"
	},
	{
		key: "Zambia",
		text: "Zambia",
		name: "Zambia",
		iso2: "ZM",
		iso3: "ZMB",
		phone_code: "+260"
	},
	{
		key: "Zimbabwe",
		text: "Zimbabwe",
		name: "Zimbabwe",
		iso2: "ZW",
		iso3: "ZWE",
		phone_code: "+263"
	}
];

export default CountryPrefixes;
