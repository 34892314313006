import { Link, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

// Styles
import styles from "./index.module.css";
import logo from "../../../assets/images/taxmm-logo.svg";
import logoMini from "../../../assets/images/taxmm-logo.svg";
import { IoIosPeople, IoMdPerson } from "react-icons/io";
import { AiOutlineBarChart } from "react-icons/ai";
import { MdLocalPlay, MdShoppingCart } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { FiSettings } from "react-icons/fi";
import { FaFileContract, FaPeopleCarry, FaEnvelope } from "react-icons/fa";
import { MailIcon, TextDocumentIcon, TextDocumentSharedIcon } from "@fluentui/react-icons-mdl2";

type Props = {
	displayNavbar: boolean;
};

function SideBar(props: Props) {
	const matchW = useMediaQuery("(max-width: 1000px)");
	const matchH = useMediaQuery("(max-height: 767px)");
	const heightToSmall = useMediaQuery("(max-height: 524px)");
	const responsive = matchW || matchH;
	let iconSize = responsive ? 30 : 40;
	iconSize = heightToSmall ? 20 : iconSize;
	const { displayNavbar } = props;

	return (
		<div className={styles.SideBar} style={{ display: displayNavbar ? "inline" : "none" }}>
			<div className={styles.SideBarContent}>
				{!heightToSmall && (
					<Link to="/">
						<img src={responsive ? logoMini : logo} className={responsive ? styles.LogoMini : styles.Logo} alt="logo" />
					</Link>
				)}
				<Nav className={styles.LinkList}>
					<div className={styles.LinkContainer}>
						<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/home">
							<AiOutlineBarChart size={iconSize} />
							<br />
							<div className={styles.LinkName}>
								<FormattedMessage id="sidebar.home" />
							</div>
						</NavLink>
					</div>
					<div className={styles.LinkContainer}>
						<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/products">
							<MdLocalPlay size={iconSize} />
							<br />
							<div className={styles.LinkName}>
								<FormattedMessage id="sidebar.products" />
							</div>
						</NavLink>
					</div>
                    <div className={styles.LinkContainer}>
                        <NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/producers">
                            <FaPeopleCarry size={iconSize} />
                            <br />
                            <div className={styles.LinkName}>
                                <FormattedMessage id="sidebar.producers" />
                            </div>
                        </NavLink>
                    </div>
                    <div className={styles.LinkContainer}>
						<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/investors">
							<IoIosPeople size={iconSize} />
							<br />
							<div className={styles.LinkName}>
								<FormattedMessage id="sidebar.investors" />
							</div>
						</NavLink>
					</div>
					<div className={styles.LinkContainer}>
						<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/opportunities">
							<MdShoppingCart size={iconSize} />
							<br />
							<div className={styles.LinkName}>
								<FormattedMessage id="sidebar.opportunities" />
							</div>
						</NavLink>
					</div>
					<div className={styles.LinkContainer}>
						<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/contracts">
							<FaFileContract size={iconSize} />
							<br />
							<div className={styles.LinkName}>
								<FormattedMessage id="sidebar.contracts" />
							</div>
						</NavLink>
					</div>
                    <div className={styles.LinkContainer}>
                        <NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/letters">
                            <FaEnvelope size={iconSize} />
                            <br />
                            <div className={styles.LinkName}>
                                <FormattedMessage id="sidebar.letters" />
                            </div>
                        </NavLink>
                    </div>
                    <div className={styles.LinkContainer}>
						<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/settings">
							<FiSettings size={iconSize} />
							<br />
							<div className={styles.LinkName}>
								<FormattedMessage id="sidebar.configuration" />
							</div>
						</NavLink>
					</div>
				</Nav>
			</div>
		</div>
	);
}

export default SideBar;
