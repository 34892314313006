import React from "react";
import { useEffect, useState } from "react";
import { pageActions as actions } from "../../../store/page";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { Col, Row, Form } from "react-bootstrap";
import ContentBox from "../../../components/ContentBox";


type Props = {
    updatePageHeaderTitle: (title: string) => void;
    finishStepTwo: (document: any) => any;
};

const LetterCreationStepOne = (props: Props) =>
{
    const { updatePageHeaderTitle, finishStepTwo } = props;
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const [formatError, setFormatError] = React.useState(false);
    const [isFileOnState, setIsFileOnState] = React.useState(false);
    const [fileUploaded, setFileUploaded] = React.useState(false);

    React.useEffect(() =>
    {
        updatePageHeaderTitle("Crear Carta. Paso 2. Subir documento");
    }, [updatePageHeaderTitle]);

    const fileUploadOnChangeHandler = (event: any) =>
    {
        if (checkValidFile(event.target.files[0]))
        {
            finishStepTwo(event.target.files[0]);
        }
    };


    const checkValidFile = (file: any) =>
    {
        if (file && !(file!["type"] === "application/pdf"))
        {
            setFormatError(true);
            return false;
        }
        if (file)
        {
            setIsFileOnState(true);
            setFormatError(false);
            return true;
        }
        setIsFileOnState(false);
        setFormatError(false);
        return false;
    };


    return (
        <ContentBox>
            <Col lg={6}>
                <style>
                    {`
                  .form-control::file-selector-button {
                    height: 57px !important;
                  }
                    `}
                </style>
                <Form.Group controlId="formFile">
                    <label style={{ marginBottom: "10px" }}>Sube la carta que te ha proporcionado el inversor</label>
                    <Form.Control
                        type="file"
                        multiple={false}
                        onChange={(e: any) =>
                        {
                            fileUploadOnChangeHandler(e);
                        }}
                        placeholder="Carta de inversión"
                        ref={fileInputRef}
                    />
                </Form.Group>

            </Col>
        </ContentBox>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updatePageHeaderTitle: (title: string) => dispatch(actions.updatePageHeaderTitle(title))
});

export default connect(null, mapDispatchToProps)(LetterCreationStepOne);
