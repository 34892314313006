import Styles from "./index.module.css";

type Props = {
	headLabel?: string | JSX.Element;
	children: JSX.Element | JSX.Element[];
	style?: any;
};

const ContentBox = (prop: Props) => {
	const { headLabel, children, style } = prop;

	return (
		<div className={Styles.divBoxContent} style={style}>
			{headLabel && <div className={Styles.headLabel}>{headLabel}</div>}
			{children}
		</div>
	);
};

export default ContentBox;
