import React from "react";
import { useEffect, useState } from "react";
import { pageActions as actions } from "../../../store/page";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { Col, Row } from "react-bootstrap";
import { InvestorDto } from "../../../services/investorServices";
import InvestorServices, {
} from "../../../services/investorServices";
import { investorTypePerson } from "../../../types/InvestorTypes";
import Input from "../../../components/Form/Input";
import DataTable from "../../../components/Table";
import Select from "../../../components/Form/Select";
import ContactServices, { ContactDto } from "../../../services/contactServices";
import ContentBox from "../../../components/ContentBox";


type Props = {
    updatePageHeaderTitle: (title: string) => void;
    finishStepOne: (idInvestor?: number) => any;
};

const LetterCreationStepOne = (props: Props) =>
{
    const { updatePageHeaderTitle, finishStepOne } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [investors, setInvestors] = useState<InvestorDto[]>([]);
    const [userId, setUserId] = useState<number>(0);
    const [investorNameFilter, setInvestorNameFilter] = React.useState<string>("");
    const [investorTypePersonFilter, setInvestorTypePersonFilter] = React.useState<number>();
    const [investorContactTaxMMFilter, setInvestorContactTaxMMFilter] = React.useState<number>(0);
    const [investorCifFilter, setInvestorCifFilter] = React.useState<string>("");
    const [investorFinderFilter, serInvestorFinderFilter] = React.useState<string>("");
    const [filteredInvestors, setFilteredInvestors] = React.useState<InvestorDto[]>([]);
    const [contactsTaxmm, setContactsTaxmm] = React.useState<ContactDto[]>([]);

    React.useEffect(() =>
    {
        updatePageHeaderTitle("Crear Carta. Paso 1. Seleccionar inversor");
    }, [updatePageHeaderTitle]);

    useEffect(() =>
    {
        setIsLoading(true);
        InvestorServices.getInvestors()
            .then((response: InvestorDto[]) =>
            {
                setInvestors(response)
                setFilteredInvestors(response)
            })
            .finally(() =>
            {
                setIsLoading(false);
            });
    }, []);

    React.useEffect(() =>
    {
        ContactServices.getContactsTaxmm().then(response =>
        {
            setContactsTaxmm(response);
        });
    }, []);


    React.useEffect(() =>
    {
        let filteredInvestors = investors
            ?.filter(x => (x.name ?? "").toLowerCase().includes(investorNameFilter))
            .filter(x => (x.cif ?? "").toLowerCase().includes(investorCifFilter))
            .filter(x => x.typePersonId === investorTypePersonFilter || !investorTypePersonFilter)
            .filter(x => x.contactTaxmmId === investorContactTaxMMFilter || investorContactTaxMMFilter === 0)
            .filter(x => (x.finder?.email ?? "").toLowerCase().includes(investorFinderFilter));
        setFilteredInvestors(filteredInvestors);
    }, [investorNameFilter, investorTypePersonFilter, investorCifFilter, investorContactTaxMMFilter, investorFinderFilter, investors]);

    function filterInvestorNameOnChangeHandler(e: any)
    {
        setInvestorNameFilter(e.target.value.toLowerCase());
    }

    function filterInvestorTypePersonOnChangeHandler(e: any)
    {
        setInvestorTypePersonFilter(e.target.value);
    }

    function filterInvestorCifOnChangeHandler(e: any)
    {
        setInvestorCifFilter(e.target.value.toLowerCase());
    }

    function filterContactTaxMMOnChangeHandler(e: any)
    {
        let value = Number(e.target.value);
        setInvestorContactTaxMMFilter(value);
    }

    function filterInvestorFinderOnChangeHandler(e: any)
    {
        serInvestorFinderFilter(e.target.value.toLowerCase());
    }


    return (
        <>
            <ContentBox>
                <Row>
                    <Col lg={3} style={{ paddingBottom: "15px" }}>
                        <Input name="filterInvestorName" type="text" label="Buscar por el nombre del inversor" onChange={filterInvestorNameOnChangeHandler}></Input>
                    </Col>
                    <Col lg={3} style={{ paddingBottom: "15px" }}>
                        <Input name="filterInvestorCif" type="text" label="Buscar por el CIF" onChange={filterInvestorCifOnChangeHandler}></Input>
                    </Col>
                    <Col lg={3} style={{ paddingBottom: "15px" }}>
                        <Select name="filterTypePerson" label="Buscar por el tipo de persona" onChange={filterInvestorTypePersonOnChangeHandler}>
                            {investorTypePerson &&
                                investorTypePerson.map(item => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                        </Select>
                    </Col>
                    <Col lg={3} style={{ paddingBottom: "15px" }}>
                        <Select name="filterContactTaxmm" label="Contacto TaxMM" onChange={filterContactTaxMMOnChangeHandler}>
                            {contactsTaxmm &&
                                contactsTaxmm.map(type => (
                                    <option key={type?.id} value={type?.id}>
                                        {type?.name}
                                    </option>
                                ))}
                        </Select>
                    </Col>
                    <Col lg={3} style={{ paddingBottom: "15px" }}>
                        <Input
                            name="filterInvestorFinder"
                            type="text"
                            label="Buscar por el email del finder"
                            onChange={filterInvestorFinderOnChangeHandler}
                        ></Input>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <DataTable
                            id="investorTable"
                            size="lg"
                            isLoading={isLoading}
                            totalRows={filteredInvestors.length}
                            selectedRowsPerPage={10}
                            head={
                                <tr key="headerkey">
                                    <th>Inversor</th>
                                    <th>CIF</th>
                                </tr>
                            }
                            body={filteredInvestors.map((investor, index) => (
                                <tr
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                    {
                                        finishStepOne(investor.id)
                                    }}
                                >
                                    <td>
                                        {investor.name}
                                    </td>
                                    <td>{investor.cif}</td>
                                </tr>
                            ))}
                        />
                    </Col>
                </Row>
            </ContentBox>
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updatePageHeaderTitle: (title: string) => dispatch(actions.updatePageHeaderTitle(title))
});

export default connect(null, mapDispatchToProps)(LetterCreationStepOne);
