import Select from "../Select";

type Props = any;

const SelectField = (props: Props) => {
	const {
		children,
		placeholder,
		onChange,
		label,
		isInvalid,
		disabled,
		invalidText,
		field: { name, value, onChange: FieldOnChange }
	} = props;

	const changeHandler = (e: any) => {
		FieldOnChange(e);
		if (onChange) onChange(e);
	};

	return (
		<Select
			children={children}
			placeholder={placeholder}
			onChange={changeHandler}
			label={label}
			isInvalid={isInvalid}
			disabled={disabled}
			name={name}
			value={value}
			invalidText={invalidText}
		></Select>
	);
};

export default SelectField;
