import React from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
	title?: string;
	body?: string | JSX.Element | JSX.Element[];
	btnSubmitText?: string;
	btnSubmitDisabled?: boolean;
	onSubmit?: any;
	onClose?: any;
	size?: "sm" | "lg" | "xl";
	showModal: boolean;
};

export const BasicModal = (props: Props) => {
	const { body, title, btnSubmitText, showModal, btnSubmitDisabled, size } = props;
	const [show, setShow] = React.useState(false);

	const handleClose = () => {
		setShow(false);
		if (props.onClose !== undefined) {
			props.onClose();
		}
	};

	React.useEffect(() => {
		setShow(showModal);
	}, [showModal]);

	const onSubmit = async () => {
		handleClose();
		if (props.onSubmit !== undefined) {
			props.onSubmit();
		}
	};

	return (
		<>
			<Modal show={show} onHide={handleClose} size={size}>
				<Modal.Header closeButton>
					<b>{title}</b>
				</Modal.Header>
				<Modal.Body>{body}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					{btnSubmitText && (
						<Button
							disabled={btnSubmitDisabled}
							onClick={() => {
								onSubmit();
							}}
						>
							{btnSubmitText}
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
};
