import React from "react";
import { useState } from "react";
import { pageActions as actions } from "../../store/page";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import ContentBox from "../../components/ContentBox";
import Loading from "../../components/Loading";
import { BackButton } from "../../components/BackButton";
import LetterCreationStepOne from "./LetterCreationStepOne";
import LetterCreationStepTwo from "./LetterCreationStepTwo";
import LetterCreationStepThree from "./LetterCreationStepThree";
import InvestorServices, { InvestorDto } from "../../services/investorServices";
import { Button } from "react-bootstrap";

type Props = {
    updatePageHeaderTitle: (title: string) => void;
};

const LetterCreation = (props: Props) =>
{
    const { updatePageHeaderTitle } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [step, setStep] = useState(0);

    const [investorData, setInvestorData] = useState<InvestorDto>();
    const [selectedFile, setSelectedFile] = React.useState();


    const finishStepOne = (investorId?: number) =>
    {
        setIsLoading(true);

        if (investorId != undefined)
        {
            InvestorServices.getInvestor(investorId)
                .then((response: InvestorDto) =>
                {
                    setInvestorData(response);
                })
                .finally(() =>
                {
                    setIsLoading(false);
                    setStep(1);
                });
        }
    }

    const finishStepTwo = async (document?: any) =>
    {
        await setIsLoading(true);
        await setSelectedFile(document);
        await setIsLoading(false);
        await setStep(2);
    }


    return (
        <>
            <BackButton />
            {isLoading && <Loading type="component"></Loading>}
            {!isLoading &&
                <>
                    {step === 0 && (<LetterCreationStepOne finishStepOne={(investor?: number) => finishStepOne(investor)} />)}
                    {step === 1 && (<LetterCreationStepTwo finishStepTwo={(document?: number) => finishStepTwo(document)} />)}
                    {step === 2 && (<LetterCreationStepThree investorData={investorData} document={selectedFile} />)}
                </>
            }
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updatePageHeaderTitle: (title: string) => dispatch(actions.updatePageHeaderTitle(title))
});

export default connect(null, mapDispatchToProps)(LetterCreation);
