import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import IbanTrade from "../views/IbanTrade";
import LetterCreation from "../views/LetterCreation";
import AssigmentEmails from "../views/Assignments/AssigmentEmailsAIE";
import AssigmentEmailsAIE from "../views/Assignments/AssigmentEmailsAIE";
import AssigmentEmailsFin from "../views/Assignments/AssigmentEmailsFin";

const Home = lazy(() => import("../views/Home"));

const Assignments = lazy(() => import("../views/Assignments"));
const AssignmentsCreate = lazy(() => import("../views/Assignments/AssignmentsCreate"));
const AssignmentUploadFiles = lazy(() => import("../views/Assignments/AssignmentUploadFiles"));
const AssignmentSelectInvestors = lazy(() => import("../views/Assignments/AssignmentSelectInvestors"));
const AssignmentAssignInvestment = lazy(() => import("../views/Assignments/AssignmentAssignInvestment"));
const Investors = lazy(() => import("../views/Investors"));
const Investor = lazy(() => import("../views/Investor"));
const Products = lazy(() => import("../views/Products"));
const Product = lazy(() => import("../views/Product"));
const Opportunity = lazy(() => import("../views/Opportinity"));
const Opportunities = lazy(() => import("../views/Opportunities"));
const Contract = lazy(() => import("../views/Contract"));
const Contracts = lazy(() => import("../views/Contracts"));
const ContactsTaxmm = lazy(() => import("../views/Contacts/Taxmm"));
const ContactsFinder = lazy(() => import("../views/Contacts/Finder"));
const ContactTaxmm = lazy(() => import("../views/Contact/Taxmm"));
const ContactFinder = lazy(() => import("../views/Contact/Finder"));
const DocumentsModel = lazy(() => import("../views/DocumentsModel"));
const InternalDocs = lazy(() => import("../views/InternalDocs"));
const SharedDocuments = lazy(() => import("../views/SharedDocuments"));
const CoProducers = lazy(() => import("../views/CoProducers"));
const CoProducer = lazy(() => import("../views/CoProducer"));
const Settings = lazy(() => import("../views/Settings"));
const NotFound = lazy(() => import("../views/NotFound"));
const LettersView = lazy(() => import("../views/Letters"));
// const ContactAdmin = lazy(() => import("../views/Contact/Admin"));
// const ContactsAdmin = lazy(() => import("../views/Contacts/Admin"));

const CustomRoutes = () => {
	return (
		<Suspense>
			<Routes>
				<Route path="/" element={<Navigate to="/home" />} />
				<Route path="/assignments" element={<Assignments />} />
				<Route path="/assignments/create" element={<AssignmentsCreate />} />
				<Route path="/assignments/:productId/uploadFiles" element={<AssignmentUploadFiles />} />
				<Route path="/assignments/:productId/selectInvestors" element={<AssignmentSelectInvestors />} />
				<Route path="/assignments/:productId/assignInvestment" element={<AssignmentAssignInvestment />} />
                <Route path="/assignments/:productId/email-aie" element={<AssigmentEmailsAIE />} />
                <Route path="/assignments/:productId/email-fin" element={<AssigmentEmailsFin />} />
				<Route path="/home" element={<Home />} />
				<Route path="/investors" element={<Investors />} />
				<Route path="/investors/new" element={<Investor method="create" />} />
				<Route path="/investors/:id" element={<Investor method="view" />} />
				<Route path="/investors/:id/edit" element={<Investor method="edit" />} />
				<Route path="/products" element={<Products />} />
				<Route path="/products/new" element={<Product method="create" />} />
				<Route path="/products/:id" element={<Product method="view" />} />
				<Route path="/products/:id/edit" element={<Product method="edit" />} />
				<Route path="/products/:id/duplicate" element={<Product method="duplicate" />} />
				<Route path="/opportunities" element={<Opportunities />} />
				<Route path="/opportunities/new" element={<Opportunity method="create" />} />
				<Route path="/opportunities/:id" element={<Opportunity method="view" />} />
				<Route path="/opportunities/:id/edit" element={<Opportunity method="edit" />} />
				<Route path="/contracts" element={<Contracts />} />
				<Route path="/contracts/new" element={<Contract method="create" />} />
				<Route path="/contracts/:id" element={<Contract method="view" />} />
				<Route path="/contracts/:id/edit" element={<Contract method="edit" />} />
                <Route path="/letters" element={<LettersView />} />
                <Route path="/letters/new" element={<LetterCreation />} />
				<Route path="/settings" element={<Settings />} />
				<Route path="/settings/document-model" element={<DocumentsModel />} />
				<Route path="/settings/contacts/taxmm" element={<ContactsTaxmm />} />
				<Route path="/settings/contacts/taxmm/new" element={<ContactTaxmm method="create" />} />
				<Route path="/settings/contacts/taxmm/:id" element={<ContactTaxmm method="view" />} />
				<Route path="/settings/contacts/taxmm/:id/edit" element={<ContactTaxmm method="edit" />} />
				<Route path="/settings/contacts/finder" element={<ContactsFinder />} />
				<Route path="/settings/contacts/finder/new" element={<ContactFinder method="create" />} />
				<Route path="/settings/contacts/finder/:id" element={<ContactFinder method="view" />} />
				<Route path="/settings/contacts/finder/:id/edit" element={<ContactFinder method="edit" />} />
				{/* <Route path="/settings/contacts/admin" element={<ContactsAdmin />} />
				<Route path="/settings/contacts/admin/new" element={<ContactAdmin method="create" />} />
				<Route path="/settings/contacts/admin/:id" element={<ContactAdmin method="view" />} />
				<Route path="/settings/contacts/admin/:id/edit" element={<ContactAdmin method="edit" />} /> */}
                <Route path="producers" element={<CoProducers />} />
                <Route path="producers/new" element={<CoProducer method="create" />} />
                <Route path="producers/:id" element={<CoProducer method="view" />} />
                <Route path="producers/:id/edit" element={<CoProducer method="edit" />} />
				<Route path="/settings/internal-docs" element={<InternalDocs />} />
                <Route path="/settings/iban-trade" element={<IbanTrade />} />
				<Route path="/settings/sharedDocuments" element={<SharedDocuments />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Suspense>
	);
};

export default CustomRoutes;
