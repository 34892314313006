import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
	id: string | number;
	tooltip: string;
	icon: JSX.Element;
	onClick?: any;
	tooltipPlacement?: "top" | "right" | "bottom" | "left";
	disabled?: boolean;
};

const IconButton = (prop: Props) => {
	const { id, tooltip, icon, onClick, tooltipPlacement, disabled } = prop;

	return (
		<OverlayTrigger
			key={"tooltip-trigger-" + id}
			placement={tooltipPlacement ?? "top"}
			trigger={["hover", "hover"]}
			overlay={<Tooltip id={"tooltip-" + id}>{tooltip}</Tooltip>}
		>
			<Button variant="flat" disabled={disabled} onClick={onClick} style={{ padding: "0px", marginRight: "15px", border: "0px" }}>
				{icon}
			</Button>
		</OverlayTrigger>
	);
};

export default IconButton;
