import React from "react";
import "./App.css";
import Body from "./components/PageLayout/Body";
import Footer from "./components/PageLayout/Footer";
import Header from "./components/PageLayout/Header";
import CustomNavbar from "./components/PageLayout/SideBar";
import CustomRoutes from "./routes";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
	const [displayNavbar, setDisplayNavbar] = React.useState<boolean>(true);
	const { instance } = useMsal();

	React.useEffect(() => {
		const accounts = instance.getAllAccounts();
		if (accounts.length > 0) {
			instance.setActiveAccount(accounts[0]);
		}

		instance.addEventCallback((event: { eventType: EventType; payload: { account: any } }) => {
			if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
				const account = event.payload.account;
				instance.setActiveAccount(account);
			}
		});
	}, [instance]);

	return (
		<div className="App">
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
				<CustomNavbar displayNavbar={displayNavbar} />
				<div className="AppContainer">
					<Header
						displayNavBar={display => {
							setDisplayNavbar(display);
						}}
					/>
					<Body>
						<AuthenticatedTemplate>
							<ScrollToTop />
							<CustomRoutes />
						</AuthenticatedTemplate>
					</Body>
					<Footer />
				</div>
			</MsalAuthenticationTemplate>
		</div>
	);
};

export default App;
