import axios from "axios";
import config from "../config";
const proxy: string | undefined = config.PROXY_URL;

export type ContactDto = {
	id?: number;
	name: string;
	nif?: string;
	email?: string;
	countryPrefix?: string;
	phone?: number;
	createdAt?: string;
	updatedAt?: string;
};

class ContactsServices {
	public getContactsTaxmm = async (): Promise<ContactDto[]> => {
		let url = `${proxy}/contacts/taxmm-contacts`;
		const req = await axios.get(url);
		return req.data;
	};

	public getContactTaxmm = async (id: number): Promise<ContactDto> => {
		let url = `${proxy}/contact/taxmm-contact/${id}`;
		const req = await axios.get(url);
		return req.data;
	};

	public postContactTaxmm = async (contact: ContactDto): Promise<ContactDto> => {
		let url = `${proxy}/contact/taxmm-contact/0`;
		const req = await axios.post(url, contact);
		return req.data;
	};

	public putContactTaxmm = async (id: number, contact: ContactDto): Promise<ContactDto> => {
		let url = `${proxy}/contact/taxmm-contact/${id}`;
		const req = await axios.put(url, contact);
		return req.data;
	};

	public deleteContactTaxmm = async (id: number): Promise<any> => {
		let url = `${proxy}/contact/taxmm-contact/${id}`;
		const req = await axios.delete(url);
		return req.data;
	};

	public getContactsFinder = async (): Promise<ContactDto[]> => {
		let url = `${proxy}/contacts/finder-contacts`;
		const req = await axios.get(url);
		return req.data;
	};

	public getContactFinder = async (id: number): Promise<ContactDto> => {
		let url = `${proxy}/contact/finder-contact/${id}`;
		const req = await axios.get(url);
		return req.data;
	};

	public postContactFinder = async (contact: ContactDto): Promise<ContactDto> => {
		let url = `${proxy}/contact/finder-contact/0`;
		const req = await axios.post(url, contact);
		return req.data;
	};

	public putContactFinder = async (id: number, contact: ContactDto): Promise<ContactDto> => {
		let url = `${proxy}/contact/finder-contact/${id}`;
		const req = await axios.put(url, contact);
		return req.data;
	};

	public deleteContactFinder = async (id: number): Promise<any> => {
		let url = `${proxy}/contact/finder-contact/${id}`;
		const req = await axios.delete(url);
		return req.data;
	};

	public getContactsAdmin = async (): Promise<ContactDto[]> => {
		let url = `${proxy}/contacts/admin-contacts`;
		const req = await axios.get(url);
		return req.data;
	};

	public getContactAdmin = async (id: number): Promise<ContactDto> => {
		let url = `${proxy}/contact/admin-contact/${id}`;
		const req = await axios.get(url);
		return req.data;
	};

	public postContactAdmin = async (contact: ContactDto): Promise<ContactDto> => {
		let url = `${proxy}/contact/admin-contact/0`;
		const req = await axios.post(url, contact);
		return req.data;
	};

	public putContactAdmin = async (id: number, contact: ContactDto): Promise<ContactDto> => {
		let url = `${proxy}/contact/admin-contact/${id}`;
		const req = await axios.put(url, contact);
		return req.data;
	};

	public deleteContactAdmin = async (id: number): Promise<any> => {
		let url = `${proxy}/contact/admin-contact/${id}`;
		const req = await axios.delete(url);
		return req.data;
	};
}

const ContactServices = new ContactsServices();
export default ContactServices;
