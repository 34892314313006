import axios from "axios";
import config from "../config";
const proxy: string | undefined = config.PROXY_URL;

export type ContractDocsVersionDto = {
	id: number;
	version: string;
	html: string;
	contractDocId: number;
	contractDoc: ContractDocDto;
	createdAt: string;
	updatedAt: string;
};

export type ContractDocDto = {
	id: number;
	name: string;
	slug: string;
	createdAt: string;
	updatedAt: string;
};

class ContractDocsServices {
	public getLastContractVersion = async (contractDocsName: string): Promise<ContractDocsVersionDto> => {
		let url = `${proxy}/contractDocs/${contractDocsName}/lastVersion`;
		const req = await axios.get(url);
		return req.data;
	};
}

const ContractDocServices = new ContractDocsServices();
export default ContractDocServices;
