import axios from "axios";
import config from "../config";
import { AdministratorDto } from "./administratorServices";
const proxy: string | undefined = config.PROXY_URL;

export type CoProducerDto = {
	id: number;
	name: string;
	cif?: string;
	administratorName?: string;
    administratorDni?: string;
	countryPrefix?: string;
	phone?: number;
	email?: string;
	address?: string;
	createdAt: string;
	updatedAt: string;
    bankAccounts?: ProducerBankAccountDto[];
    cnae?: number;
};

export type LegalRepresentants = {
    administratorName?: string;
    administratorDni?: string;
    coProducerName?: string;
}

export type ProducerBankAccountDto = {
	id: number;
	idProducer: number,
    name: string;
    iban: string;
    isDefault: boolean;    
};

class CoProducerServices {
	public getCoProducers = async (): Promise<CoProducerDto[]> => {
		let url = `${proxy}/co-producers`;
		const req = await axios.get(url);
		return req.data;
	};

	public getCoProducer = async (id: number): Promise<CoProducerDto> => {
		let url = `${proxy}/co-producer/${id}`;
		const req = await axios.get(url);
		return req.data;
	};

	public postCoProducer = async (coProducer: CoProducerDto): Promise<CoProducerDto> => {
		let url = `${proxy}/co-producer/0`;
		const req = await axios.post(url, coProducer);
		return req.data;
	};

	public putCoProducer = async (id: number, coProducer: CoProducerDto): Promise<CoProducerDto> => {
		let url = `${proxy}/co-producer/${id}`;
		const req = await axios.put(url, coProducer);
		return req.data;
	};

	public deleteCoProducer = async (id: number): Promise<any> => {
		let url = `${proxy}/co-producer/${id}`;
		const req = await axios.delete(url);
		return req.data;
	};

    public getBankAccountProducer = async (productId: number): Promise<ProducerBankAccountDto[]> => {
		let url = `${proxy}/co-producer/bank-account/${productId}`;
		const req = await axios.get(url);
		return req.data;
	};

    public postBankAccountProducer = async (bankAccountValues: ProducerBankAccountDto): Promise<CoProducerDto> => {
		let url = `${proxy}/co-producer/bank-account/0`;
		const req = await axios.post(url, bankAccountValues);
		return req.data;
	};

	public putBankAccount =async (id: number): Promise<any> => {
		let url = `${proxy}/co-producer/bank-account/${id}`;
		const req = await axios.put(url, id);
		return req.data;
	};

    public deleteBankAccount = async (id: number): Promise<any> => {
		let url = `${proxy}/co-producer/bank-account/${id}`;
		const req = await axios.delete(url);
		return req.data;
	};
}

const CoProducerService = new CoProducerServices();
export default CoProducerService;
