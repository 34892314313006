import axios from "axios";
import config from "../config";
import { ContractDto } from "./contractServices";
import { OpportunityDto } from "./opportunityServices";
import { FinderDto } from "./finderService";
import { ContactDto } from "./contactServices";
import { AttachmentsResponse } from "./attachmentServices";
import { AnnualInvestmentCharterDto } from "./annualInvestmentChartersServices";
const proxy: string | undefined = config.PROXY_URL;

export type InvestorDto = {
	id?: number;
	name?: string;
	typePerson?: string;
	typePersonId?: number;
	address?: string;
	cif?: string;
	email?: string;
	countryPrefix?: string;
	phone?: string;
	contactTaxmm?: ContactDto;
	contactTaxmmId?: number;
	finderId?: number;
	city?: string;
	postalCode?: number;
	taxRate?: number;
	naturalPersonInvestorId?: number;
	naturalPersonInvestor?: NaturalPersonInvestorDto;
	legalPersonInvestorId?: number;
	legalPersonInvestor?: LegalPersonInvestorDto;
	finder?: FinderDto;
	annualInvestmentCharters?: AnnualInvestmentCharterDto[];
    pendingInvert?: number;
    totalInvesionCompromet?: number;
    opportunities?: OpportunityDto[];
};

export type IbanTradeAccount = {
	id: number;
    name: string;
    iban: string;
    isDefault: boolean;   
    email: string; 
};

export enum maritalStatuses {
	"Soltero/a",
	"Casado/a",
	"Divorciado/a",
	"Viudo/a"
}

export enum matrimonialRegimes {
	"Gananciales",
	"Separación de bienes"
}

export enum representativePositions {
	"Administrador único",
	"Administrador solidario",
	"Administradores mancomunados",
	"Consejero Delegado",
	"Apoderado"
}

export type NaturalPersonInvestorDto = {
	id?: number;
	profession?: string;
	maritalStatus?: maritalStatuses;
	matrimonialRegime?: matrimonialRegimes;
	spouseFullname?: string;
	spouseDocument?: string;
	spouseAddress?: string;
	spouseProfession?: string;
	createdAt?: string;
	updatedAt?: string;
    spanishNatitonality?: boolean;
    incomeOfEconomicActivities?: boolean;
};

export type LegalPersonInvestorDto = {
	id?: number;
	cnae?: string;
	fiscalYearEndDate?: string;
	representativePosition?: number;
	representatives?: RepresentativeDto[];
	annualBilling?: number;
	createdAt?: string;
	updatedAt?: string;
};

export type RepresentativeDto = {
	id?: number;
	legalPersonInvestorId?: number;
	fullname?: string;
	document?: string;
	countryPrefix?: string;
	phone?: string;
	email?: string;
	createdAt?: string;
	updatedAt?: string;
};

export type Investment = {
	id?: number;
	year?: number;
	investProbabilityInfo?: string;
	amount?: number;
	prudentInvestment?: number;
	positiveInvestment?: number;
	financing?: string;
	operationConfirmed?: string;
	productProyect?: string;
	productId?: number;
	naturalPersonInvestorId?: number;
	naturalPersonInvestor?: NaturalPersonInvestorDto;
	legalPersonInvestorId?: number;
	legalPersonInvestor?: LegalPersonInvestorDto;
};

export type InvestorsDocumentCategoryDto = {
	id: number;
	name: string;
	investorFiles: AttachmentsResponse[];
};

class InvestorsServices {
	public getInvestor = async (investorId: number): Promise<InvestorDto> => {
		let url = `${proxy}/investor/${investorId}`;
		const req = await axios.get(url);
		return req.data;
	};

	public postInvestor = async (investor: InvestorDto): Promise<InvestorDto> => {
		let url = `${proxy}/investor/0`;
		const req = await axios.post(url, investor);
		return req.data;
	};

	public putInvestor = async (investorId: number, investor: InvestorDto): Promise<InvestorDto> => {
		let url = `${proxy}/investor/${investorId}`;
		const req = await axios.put(url, investor);
		return req.data;
	};

	public deleteInvestor = async (investorId: number): Promise<any> => {
		let url = `${proxy}/investor/${investorId}`;
		const req = await axios.delete(url);
		return req.data;
	};

	public getInvestors = async (): Promise<InvestorDto[]> => {
		let url = `${proxy}/investors?hasClaims=true`;
		const req = await axios.get(url);
		return req.data;
	};

	public importInvestors = async (formData: FormData): Promise<string[]> => {
		let url = `${proxy}/investors/process-file`;
		const req = await axios.post(url, formData);
		return req.data;
	};

	public getInvestorAttachments = async (id: number): Promise<InvestorsDocumentCategoryDto[]> => {
		let url = `${proxy}/investor/${id}/documents`;
		const req = await axios.get(url);
		return req.data;
	};

	public deleteInvestorAttachments = async (id: number, filesIds: number[]): Promise<number[]> => {
		let url = `${proxy}/investor/${id}/documents`;
		const req = await axios.delete(url);
		return req.data;
	};

	public addInvestorAttachments = async (id: number, formData: FormData, categoryId: number, year?: number): Promise<string[]> => {
		let url = `${proxy}/investor/${id}/documents?CategoryId=${categoryId}`;

		if (year) {
			url += `&Year=${year}`;
		}

		const req = await axios.post(url, formData);
		return req.data;
	};

	public getInvestorDocumentCategories = async (): Promise<InvestorsDocumentCategoryDto[]> => {
		let url = `${proxy}/investor/document/categories`;
		const req = await axios.get(url);
		return req.data;
	};

	public getInvestorOpportunities = async (investorId: number): Promise<OpportunityDto[]> => {
		let url = `${proxy}/investor/${investorId}/opportunities`;
		const req = await axios.get(url);
		return req.data;
	};

	public getInvestorContracts = async (investorId: number): Promise<ContractDto[]> => {
		let url = `${proxy}/investor/${investorId}/contracts`;
		const req = await axios.get(url);
		return req.data;
	};

	public downloadDocument = async (investorId: number, documentId: number): Promise<any> => {
		let url = `${proxy}/investor/${investorId}/document/${documentId}`;
		const req = await axios.get(url, { responseType: "blob" });
		return req;
	};

    public getIbansTrade = async (): Promise<IbanTradeAccount[]> => {
		let url = `${proxy}/iban-trades`;
		const req = await axios.get(url);
		return req.data;
	};

    public postIbansTrade = async (bankAccount: IbanTradeAccount): Promise<any> => {
		let url = `${proxy}/iban-trades`;
		const req = await axios.post(url, bankAccount);
		return req.data;
	};

	public deleteIbansTrade = async (idUser: number): Promise<any> => {
		let url = `${proxy}/iban-trades?iduser=${idUser}`;
		const req = await axios.delete(url);
		return req.data;
	};
}

const InvestorServices = new InvestorsServices();
export default InvestorServices;
