import * as React from "react";
import { connect } from "react-redux";
import { Nav, Navbar } from "react-bootstrap";
import styles from "./index.module.css";
import { Login, LocalizationHelper } from "@microsoft/mgt-react";
import { GlobalNavButtonIcon } from "@fluentui/react-icons-mdl2";
import { useMediaQuery } from "@mui/material";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../../../authConfig";

LocalizationHelper.strings = {
	_components: {
		login: {
			signInLinkSubtitle: "Iniciar Sesión",
			signOutLinkSubtitle: "Cerrar Sesión"
		}
	}
};

type Props = {
	header_title: String;
	displayNavBar: (display: boolean) => void;
};

const Header = (props: Props) => {
	const { header_title, displayNavBar } = props;
	const [displayNavbar, setDisplayNavbar] = React.useState<boolean>(true);
	const matchW = useMediaQuery("(max-width: 1000px)");
	const matchH = useMediaQuery("(max-height: 767px)");
	const responsive = matchW || matchH;
	const { instance } = useMsal();
	const activeAccount = instance.getActiveAccount();
	const isAuthenticated = useIsAuthenticated();

	function signOutClickHandler(instance: IPublicClientApplication) {
		instance.logoutRedirect({
			postLogoutRedirectUri: "/"
		});
	}

	function signInClickHandler(instance: IPublicClientApplication) {
		instance.loginRedirect(loginRequest);
	}

	return (
		<header className={styles.AppHeader}>
			<Navbar expand="true">
				<Nav style={{ display: "-webkit-inline-box", marginTop: "auto", marginBottom: "auto" }}>
					{responsive && (
						<div
							style={{ cursor: "pointer" }}
							onClick={() => {
								setDisplayNavbar(!displayNavbar);
								displayNavBar(!displayNavbar);
							}}
						>
							<GlobalNavButtonIcon style={{ margin: "auto 20px auto 0px" }} />
						</div>
					)}
					<Navbar.Text>
						<h4 style={{ marginBottom: "0px" }}>{header_title}</h4>
					</Navbar.Text>
				</Nav>
				<Nav>
					<Navbar.Text style={{ marginTop: "auto", marginBottom: "auto" }}>
						<Login
							userDetails={isAuthenticated ? { displayName: activeAccount?.name!, mail: activeAccount?.username! } : undefined}
							logoutInitiated={() => {
								signOutClickHandler(instance);
							}}
							loginInitiated={() => {
								signInClickHandler(instance);
							}}
						/>
					</Navbar.Text>
				</Nav>
			</Navbar>
		</header>
	);
};

const mapStateProps = (state: any) => ({
	header_title: state.page.header_title
});

export default connect(mapStateProps)(Header);
