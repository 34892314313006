import axios from "axios";
import config from "../config";
import { InvestorDto } from "./investorServices";
import { SignatureDto } from "./signatureService";
import { ContractDocsVersionDto } from "./contractDocsServices";
const proxy: string | undefined = config.PROXY_URL;

export enum ContractTypes {
	"AIE",
	"Contrato de financiación"
}

export type AnnualInvestmentCharterDto = {
	id?: number;
	contractType: ContractTypes;
	contractDocsVersion?: ContractDocsVersionDto;
	contractDocsVersionId: number;
	investorId: number;
	signatureId?: number;
	document?: string;
	amount: number;
	signingAtNotary?: boolean;
	alternativeNotary?: string;
	signAPowerOfAttorneyToTaxman?: boolean;
	spanishNatitonality?: boolean;
	incomeOfEconomicActivities?: boolean;
	createdAt?: string;
	updatedAt?: string;
	investor: InvestorDto;
	signature?: SignatureDto;
};

export type Signature = {
    id: number;
    signatureCode: string;
    status: number;
    countryPrefix: string;
    phone: string;
    expiresAt: string;
    signAt: string;
    annualInvestmentCharter: any;
    createdAt: string;
    updatedAt: string;
  };
  
  export type Investor = {
    id: number;
    countryPrefix: string;
    phone: string;
    name: string;
    email: string;
    cif: string;
    address: string;
    city: string;
    postalCode: number;
    contactTaxmmId: any;
    finderId: any;
    typePersonId: number;
    naturalPersonInvestorId: number;
    legalPersonInvestorId: any;
    taxRate: any;
    finder: any;
    contactTaxmm: any;
    typePerson: any;
    naturalPersonInvestor: any;
    legalPersonInvestor: any;
    contracts: any;
    opportunities: any;
    attachments: any;
    annualInvestmentCharters: any[];
    createdAt: string;
    updatedAt: string;
  };
  
  export type Letters = {
    id: number;
    contractType: number;
    contractDocsVersionId: number;
    investorId: number;
    document: string;
    amount: number;
    signatureId: number;
    spanishNatitonality: boolean;
    incomeOfEconomicActivities: boolean;
    signingAtNotary: boolean;
    alternativeNotary: any;
    signAPowerOfAttorneyToTaxman: boolean;
    signature: Signature;
    contractDocsVersion: any;
    investor: Investor;
    createdAt: string;
    updatedAt: string;
  };

class AnnualInvestmentChartersServices {
	public getByYearAndTypeCharter = async (params?: { year: number; contract: string }): Promise<AnnualInvestmentCharterDto[]> => {
		let url = `${proxy}/annualInvestmentCharters`;
		if (params !== undefined) {
			url = `${url}?year=${params.year}&contract=${params.contract}`;
		}
		const req = await axios.get(url);
		return req.data;
	};

    public getLetters = async (): Promise<any> => {
		let url = `${proxy}/anual-letters`;
		
		const req = await axios.get(url);
		return req.data;
	};

	public getAnnualInvestmentCharter = async (id: number): Promise<AnnualInvestmentCharterDto> => {
		const url = `${proxy}/annualInvestmentCharters/${id}`;
		const req = await axios.get(url);
		return req.data;
	};

	public getLastAnnualInvestmentCharterByUserId = async (userId: number): Promise<AnnualInvestmentCharterDto> => {
		const url = `${proxy}/lastAnnualInvestmentChartersByUserId/${userId}`;
		const req = await axios.get(url);
		return req.data;
	};

	public postAnnualInvestmentCharter = async (annualInvestmentCharterServices: AnnualInvestmentCharterDto): Promise<AnnualInvestmentCharterDto> => {
		let url = `${proxy}/annualInvestmentCharters`;
		const req = await axios.post(url, annualInvestmentCharterServices);
		return req.data;
	};

    public postManualAnnualInvestmentCharter = async (formData: FormData): Promise<any> => {
		let url = `${proxy}/manualAnnualInvestmentCharters`;
		const req = await axios.post(url, formData);
		return req.data;
	};

	public signAnnualInvestmentCharter = async (annualInvestmentCharterId: number): Promise<AnnualInvestmentCharterDto> => {
		let url = `${proxy}/annualInvestmentCharters/${annualInvestmentCharterId}/sign`;
		const req = await axios.post(url);
		return req.data;
	};


    public dowloadBase64Pdf = async (idletter: number): Promise<any> =>
        {
            let url: string = `${proxy}/anual-letters/document?idletter=${encodeURIComponent(idletter)}`;
            try {
                const req = await axios.get<any>(url, { responseType: 'blob' });
                const reader = new FileReader();
                return new Promise((resolve, reject) => {
                    reader.onloadend = () => resolve(reader.result as string);
                    reader.onerror = () => reject('Error al convertir el archivo');
                    reader.readAsDataURL(req.data);
                });
            } catch (error) {
                console.error('Error al descargar el PDF:', error);
                throw new Error('Error al descargar el PDF');
            }
        };
}

const AnnualInvestmentCharterServices = new AnnualInvestmentChartersServices();
export default AnnualInvestmentCharterServices;
