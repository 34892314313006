// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
	scopes: ["email", "openid", "profile", "user.read", "user.readBasic.all"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
	graphUsersEndpoint: "https://graph.microsoft.com/v1.0/users",
	graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
	graphMeEventsEndpoint: "https://graph.microsoft.com/v1.0/me/events"
};

export const msalConfig = {
	auth: {
		clientId: "02f36392-5d8f-4040-8895-3aff40eb366a",
		authority: "https://login.microsoftonline.com/organizations",
		redirectUri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
	}
};
