import { Col, Form } from "react-bootstrap";

type Props = any;

export const Input = (props: Props) => {
	const { name, type, label, isInvalid, invalidText, placeholder, disabled, ...rest } = props;

	return (
		<Form.Group as={Col} controlId={name}>
			{label && label !== "" && <Form.Label>{label}</Form.Label>}
			<Form.Control
				type={type}
				name={name}
				placeholder={placeholder}
				autoCorrect="off"
				autoComplete="off"
				spellCheck="false"
				isInvalid={isInvalid}
				disabled={disabled}
				{...rest}
			/>
			{invalidText && <Form.Control.Feedback type="invalid">{invalidText}</Form.Control.Feedback>}
		</Form.Group>
	);
};

export default Input;
