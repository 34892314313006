import { Col, Form } from "react-bootstrap";

type Props = any;

const Select = (props: Props) => {
	const { children, placeholder, onChange, label, isInvalid, disabled, name, invalidText, ...rest } = props;

	return (
		<Form.Group as={Col} controlId={name}>
			{label && label !== "" && <Form.Label>{label}</Form.Label>}
			<Form.Select name={name} onChange={onChange} placeholder={placeholder} isInvalid={isInvalid} disabled={disabled} {...rest}>
				<option value=""></option>
				{children}
			</Form.Select>

			{invalidText && <Form.Control.Feedback type="invalid">{invalidText}</Form.Control.Feedback>}
		</Form.Group>
	);
};

export default Select;
