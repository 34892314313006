import Input from "../Input";

type Props = any;

export const InputFieldWithDecimalLimit = (props: Props) =>
{
	const {
		type,
		label,
		placeholder,
		isInvalid,
		invalidText,
		disabled,
        decimalsLimit,
		field: { name, value, onChange, onFocus, onBlur }
	} = props;

	return (
		<Input
			value={value}
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
			name={name}
			type={type}
			isInvalid={isInvalid}
			disabled={disabled}
			label={label}
			placeholder={placeholder}
			invalidText={invalidText}
            decimalsLimit={decimalsLimit ?? 2}
            decimalScale={decimalsLimit ?? 2}
		/>
	);
};

export default InputFieldWithDecimalLimit;
