import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

type BackButtonProps = {
	backTo?: string;
};

export const BackButton = (props: BackButtonProps) => {
	const handleClick = () => {
		if (props.backTo) {
			navigate(props.backTo);
		} else {
			navigate(-1);
		}
	};
	let navigate = useNavigate();
	return (
		<Row style={{ marginBottom: "10px" }}>
			<Col>
				<Button onClick={() => handleClick() }>&laquo; Volver</Button>
			</Col>
		</Row>
	);
};
