import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import styles from "./index.module.css";

type Props = {
    type: "page" | "component" | "buttonContent";
};

const Loading = (prop: Props) => {
	const { type } = prop;

	const color = "#0d6efd";
	return (
		<>
			{type === "component" && (
				<div className={styles.wrapper}>
					<ScaleLoader color={color} />
				</div>
			)}
			{type === "page" && (
				<div className={styles.wrapperPage}>
					<ScaleLoader color={color} />
				</div>
			)}
            {type === "buttonContent" && (
                <div className={`${styles.loading} ${styles.buttonContent}`}>
                    <ScaleLoader color={color} />
                </div>
            )}
		</>
	);
};

export default Loading;
